import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import AnimateHeight from 'react-animate-height';
import styles from './Wysiwyg.module.scss';

const Wysiwyg = ({ html, isExpandable, modifiers = [] }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const expand = () => {
        setIsOpen(true);
    };

    const classes = classNames(
        styles['Wysiwyg'],
        {
            [styles['Wysiwyg--Expandable']]: isExpandable & !isOpen,
        },
        modifiers.map((modifier) => styles[`Wysiwyg--${modifier}`]),
        'Wysiwyg'
    );

    return (
        <div className={classes}>
            <AnimateHeight
                height={!isExpandable || isOpen ? 'auto' : 200}
                duration={450}
            >
                <div
                    className={styles['Wysiwyg__Html']}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </AnimateHeight>
            {isExpandable && !isOpen && (
                <button onClick={expand} className={styles['Wysiwyg__Expand']}>
                    {t('wysiwyg.expand')}
                </button>
            )}
        </div>
    );
};

Wysiwyg.propTypes = {
    html: PropTypes.string,
    isExpandable: PropTypes.bool,
    modifier: PropTypes.string,
};

Wysiwyg.defaultProps = {
    html: '',
    isExpandable: false,
};

export default Wysiwyg;
